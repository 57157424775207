<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title">{{$t('Tell us, what are you looking for?')}}</div>
          <div class="input-container">
            <input type="text" :placeholder="$t('Explore your imagination: Describe what you\'d like to see')" @input="filterProducts" v-model="search"/>
            <button class="button blue" @click="filterProducts">
              <img src="./../images/glass.svg" class="img"/>
              {{$t('Search')}}
            </button>
          </div>
          <!--          <div class="more-filters" @click="$parent.openFilterModal()">-->
          <!--            <img :src="$t('./../images/filter.svg')" class="img"/>-->
          <!--            {{$t('More filters')}}-->
          <!--          </div>-->
        </div>
      </section>
      <section class="section hero-section product-list-section-active-categories">
        <div class="wrapper">
          <div :class="{'no-tags': !activeCategories.length}" class="tags">
            <div v-for="category in this.activeCategories" class="button tags__item" :key="category.id">
              {{$t(category.title)}}
            </div>
            <div class="more-filters" @click="$parent.openFilterModal()">
              <img src="./../images/filter.svg" class="img"/>
              {{$t('More filters')}}
            </div>
          </div>
        </div>
      </section>
      <section class="section product-list-section2">
        <div class="wrapper">
          <div class="tag-search">
          </div>
          <div class="top" v-if="productList && productList.length">
            <div class="left">
              <div class="title small">{{$t('Found')}} {{productList.length}} {{ $t('items by your request') }}</div>
            </div>
            <div class="right">
              <div class="select-container">
                <select v-model="sort" @change="filterProducts">
                  <option selected hidden disabled value="">{{$t('Sort by price')}}</option>
                  <option value="price~asc">{{$t('By price: Lowest to highest')}}</option>
                  <option value="price~desc">{{$t('By price: From highest to lowest')}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="top" v-if="productList && !productList.length && (search || activeCategory)">
            <div class="left">
              <div class="title small">{{ $t('Found {amount} items by your request', {amount: productList.length}) }}</div>
            </div>
          </div>
          <transition name="fade">
            <div class="list categories" v-if="!productList.length && categoryOptions && categoryOptions.length && !search && !activeCategory">
              <div class="title">{{$t('Not sure what you are looking for? Search by categories!')}}</div>
              <div :class="['item-list', activeType]">
                <div class="item" v-for="(item, i) in filteredCategories" :key="i" @click="setCategory(item.id)">
                  <div class="img-container">
                    <img :src="$t(imgDomain + item.image_url)" class="img"/>
                  </div>
                  <div class="title small">{{$t(item.title)}}</div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="list products" v-if="productList && productList.length">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard @openBuyModal="openBuyModal" :item="item" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" :categories="categoryOptions"/>
              </div>
              <div class="title no-products" v-if="!productList.length">
                {{$t('No products!')}}
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import ProductCard from '../components/ProductCard.vue';

export default {
  name: 'ProductList',
  props: ['currency', 'filter', 'appActiveType', 'isAuth'],
  components: {
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      sort: 'price~asc',
      category: [],
      model: [],
      productList: [],
      activeCategory: '',
      activeTag: '',
      activeType: '',
      categoryOptions: [],
      search: ''
    }
  },
  mounted() {
    const sort = localStorage.getItem("sort");
    if (sort) {
      this.sort = sort;
    }
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    let filterIsSet = false;

    if (this.$route.query['filter[category]']) {
      this.activeCategory = this.$route.query['filter[category]'];
      filterIsSet = true;
    } else {
      this.activeCategory = '';
    }

    if (this.$route.query['filter[tag]']) {
      this.activeTag = this.$route.query['filter[tag]'];
      filterIsSet = true;
    } else {
      this.activeTag = '';
    }

    this.checkType();

    if (filterIsSet) {
      this.filterProducts();
    }

    this.getCategories();
  },

	watch: {
    filter: function (newArr) {
      this.filterProducts(newArr);
    },
    currency: function() {
      // this.filterProducts();
    },
    $route() {
      this.productList = [];
      this.checkType();

      if (!this.$route.query['filter[category]']) {
        this.activeCategory = ''
      }

      if (!this.$route.query['filter[tag]']) {
        this.activeTag = ''
      }
    },
    appActiveType: function() {
      this.productList = [];
      this.activeCategory = '';
    },
    isAuth: function() {
      console.log('isAuth');
      this.filterProducts();
    },
    sort: function(newSort) {
      const sort = localStorage.getItem("sort");
      if (sort != newSort) {
        localStorage.setItem("sort", newSort);
      }
      
    },
	},
  computed: {
    filteredCategories() {
      return this.categoryOptions.filter(item => (item.url.includes(this.$route.params.type) && item.parent_id !== null));
    },
    activeCategories() {
      let categoryIds = this.activeCategory;

      if (categoryIds && this.categoryOptions) {
        if (typeof categoryIds === 'string' ) {
          categoryIds = categoryIds.split(',').map((id) => parseInt(id))
        } else if (Number.isInteger(categoryIds)) {
          categoryIds = [categoryIds];
        }

        return this.categoryOptions.filter(({id}) => categoryIds.includes(id));
      }

      return [];
    }
  },
  methods: {
    checkType() {
      if (this.$route.params.type === 'images') {
        this.activeType = 'image';
      } else if (this.$route.params.type === 'texts') {
        this.activeType = 'doc';
      }
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    setCategory(id) {
      this.activeCategory = id;
      const currentPath = this.$route.path;
      const currentQuery = this.$route.query;
      const newQuery = { ...currentQuery, 'filter[category]': id };
      this.$router.replace({ path: currentPath, query: newQuery });
      this.filterProducts();
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
    filterProducts(params) {
      console.log('filterProducts', params)
      if (params && params.activeCategories && Array.isArray(params.activeCategories)) {
        const activeCategories = params.activeCategories;
        this.activeCategory = activeCategories.length ? activeCategories.map(obj => obj.id) : '';
      }

      if (params && params.activeTags && Array.isArray(params.activeTags)) {
        const activeTags = params.activeTags;
        this.activeTag = activeTags.length ? activeTags.map(obj => obj.name) : '';
      }

			let activeCategory;
			let activeTag;
      // if (paramCategories && paramCategories.length) {
      //   const paramIdies = paramCategories.map(obj => obj.id);
      //   this.activeCategory = paramIdies;
      // }
			if (this.activeCategory) {
        activeCategory = '&categories_id=' + this.activeCategory;
      } else {
        activeCategory = ''
      }

      if (this.activeTag) {
        activeTag = '&tags=' + this.activeTag;
      } else {
        activeTag = ''
      }

      let activeSort;
      if (this.sort) {
        activeSort = '&sort=' + this.sort;
      } else {
        activeSort = 'asc'
      }
      let searchText;
      if (this.search) {
        searchText = '&search=' + this.search;
      } else {
        searchText = ''
      }
			this.$http.get(process.env.VUE_APP_API + 'products/catalog?product_type=' + this.activeType + activeCategory + activeTag + activeSort + searchText )
			.then((res) => {
				this.productList = res.data.payload;
			})
			.catch(() => {
				
			})
    },
  }
}
</script>