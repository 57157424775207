<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay" @click="$parent.closeDealModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeDealModal" src="./../images/close.svg"/>
      <div class="container">
        <img class="img" src="./../images/success.svg"/>
        <div class="title">{{$t('Deal is completed')}}</div>
        <div class="desc">
          {{$t('You will receive your funds within 5 business days')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DealModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>