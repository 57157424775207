<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="left">
            <div class="title">{{$t('Create customize and innovate')}}</div>
          </div>
          <div class="right">
            <div class="desc">
              {{$t('Welcome to')}} <span>{{$t('Syntheticals')}}</span> {{$t('where AI-generated images and texts combine to fuel your creative endeavors')}}
            </div>
            <div class="title">{{$t('with us as we')}} <span>{{$t('redefine content creation.')}}</span></div>
          </div>
          <div class="down">
            <div class="desc">{{$t('Welcome to the future of creativity!')}}</div>
            <img src="./../images/down.svg" class="img"/>
          </div>
        </div>
      </div>

      <div class="section section-creator">
        <div class="wrapper">
          <div class="title">
            {{$t("In Syntheticals, you're not just a consumer, you're a")}}
          </div>
          <div class="title big">{{$t('creator')}}</div>
          <div class="flex">
            <img src="./../images/bulb.png" class="img"/>
            <div class="desc">
              {{$t("Customize and curate your content to match your vision, and watch as your ideas come to life. Whether you're a graphic designer, content marketer, or business owner, this is your one-stop destination for unleashing your creative potential.")}}
            </div>
            <div class="button-container">
              <div class="button blue" @click="explore">{{$t('Explore')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section welcome-section">
        <div class="wrapper">
          <div class="title">
            {{$t('Unlock more products by Sign Up')}}
          </div>
          <div class="tab-list">
            <div :class="['tab-list__item desc', {'active': activeType == 'image'}]" @click="changeTab('image')">{{$t('Photo')}}</div>
            <div :class="['tab-list__item desc', {'active': activeType == 'doc'}]" @click="changeTab('doc')">{{$t('Text')}}</div>
          </div>
          <div class="carousel">
            <SlickItem :categories="categories" v-if="productList && productList.length" :list="productList" @goToProduct="goToProduct" :currency="currency" />
          </div>
          <div class="button" @click="explore(activeType)">{{$t('View all')}}</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SlickItem from '../components/SlickItem.vue';


export default {
  name: 'Home',
  props: ['currency'],
  components: {
    SlickItem
  },
  data: function() {
    return {
      imgDomain: '',
      categories: [],
      productList: [],
      activeType: 'image'
    }
  },
  mounted() {
    this.getProducts();
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload;
      })
      .catch(() => {
         
      })
  },
  methods: {
    explore(type) {
      let typeOptions;
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        typeOptions = res.data.payload.filter(item => item.parent_id === null);
        console.log(typeOptions[0])
        if (type == 'doc') {
          this.$emit('chooseType', typeOptions[1]);
        } else {
          this.$emit('chooseType', typeOptions[0]);
        }
        
      })
      .catch(() => {
          
      })
    },
    changeTab(type) {
      this.activeType = type;
      this.getProducts();
    },
    getProducts() {
      this.productList = [];
			this.$http.get(process.env.VUE_APP_API + 'products/favorites')
			.then((res) => {
        this.productList = res.data.payload.filter(item => (item.product_type === this.activeType));
			})
			.catch(() => {
				
			})
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$parent.openReadyModal();
			this.$parent.setReadyPack();
		},
  
  }
}
</script>