<template>
  <main class="main page-inside escrow-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <img src="./../images/escrow/2.svg" class="decor1"/>
          <img src="./../images/escrow/1.png" class="img"/>
          <div class="text">
            <div class="title big">{{$t('Your convenient and secure escrow service')}}</div>
            <div class="title">{{$t('for purchasing digital goods')}}</div>
          </div>
        </div>
      </div>
      <div class="section escrow-step-section">
        <div class="wrapper">
          <div class="list">
            <div class="item">
              <div class="line"></div>
              <div class="title">{{$t('Client Inquiry')}}:</div>
              <div class="desc">
                {{$t("The client has expressed interest in acquiring a digital product, such as artworks or NFTs, and has initiated contact through the feedback form on our company's website.")}}
              </div>
            </div>
            <!-- Other items -->
          </div>
        </div>
      </div>
      <div class="section escrow-form-section">
        <div class="wrapper">
          <div class="title-container">
            <div class="title">{{$t('You can use the feedback form or contact us via email')}}</div>
          </div>
          <div class="left">
            <input type="email" :placeholder="$t('E-mail')" v-model="email"  class="input"/>
            <input type="text" :placeholder="$t('Name')" v-model="name" class="input"/>
            <input type="text" :placeholder="$t('Surname')" v-model="surname" class="input"/>
            <input type="text" :placeholder="$t('Topic')" v-model="topic" class="input"/>
          </div>
          <!-- Other parts of the form -->
          <button class="button blue" @click="submit">{{$t('Send request')}}</button>
        </div>

        <transition name="fade">
          <div class="desc green" v-if="success">{{$t(success)}}</div>
        </transition>
        <transition name="fade">
          <div class="desc red" v-if="$parent.error">{{$t($parent.error)}}</div>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'Escrow',
  props: [],
  components: {
  },
  data: function() {
    return {
      topic: '',
      amount: '',
      email: '',
      name: '',
      surname: '',
      message: '',
      file: '',
      success: '',
      selectedFileName: ''
    }
  },
  mounted() {
    this.$parent.getTextPageContent(this.$route.params.id);
  },
  methods: {
    setFile(event) {
      const selectedFile = event.target.files[0];
      this.file = selectedFile;
      this.selectedFileName = selectedFile ? selectedFile.name : null;
    },
    allowOnlyNumbers(){
      this.amount = this.amount.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    submit() {
      this.$emit('setError', '');
      let param = {
        'email': this.email,
        'name': this.name,
        'surname': this.surname,
        'topic': this.topic,
        'message': this.message,
        'upload_file': this.file,
        'price': this.amount
      }
      this.$http.post(process.env.VUE_APP_API + 'contact-request', param,
        {
        headers: {
          'Content-Type': 'multipart/form-data'
        }}
      )
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          self.success = 'Thank you. We will contact you soon!';
          setTimeout(()=> {
            self.success = '';
          }, 5000)
          
        } else {
          this.$emit('setError', res.data.status);
        }
        
      })
      .catch((res) => {
        let errors = res.response.data.errors;
        if (errors) {
          if (errors.email) {
            this.$emit('setError', errors.email[0]);
          } else if (errors.message) {
            this.$emit('setError', errors.message[0]);
          } else if (errors.name) {
            this.$emit('setError', errors.name[0]);
          } else if (errors.price) {
            this.$emit('setError', errors.price[0]);
          } else if (errors.surname) {
            this.$emit('setError', errors.surname[0]);
          } else if (errors.topic) {
            this.$emit('setError', errors.topic[0]);
          } else if (errors.upload_file) {
            this.$emit('setError', errors.upload_file[0]);
          } 
        } else {
          this.$emit('setError', res.response.data.message);
        }
      })
    }
  },
  
}
</script>