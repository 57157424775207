<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignUpModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignUpModal')" src="./../images/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class='form-fields register-content'>
              <div class="form-fields-wrapper">
                <div class="title">{{ $t('Sign Up') }}</div>
                <div class="desc">
                  {{ $t('Unlock more products by Sign Up') }}
                </div>
                <label>
                  <div class="desc">*{{ $t('Name') }}</div>
                  <input type="text" :placeholder="$t('Name')" v-model="name"/>
                </label>
                <label>
                  <div class="desc">*{{ $t('Surname') }}</div>
                  <input type="text" :placeholder="$t('Surname')" v-model="surname"/>
                </label>
                <label>
                  <div class="desc">*{{ $t('Phone') }}</div>
                  <input type="number" :placeholder="$t('Phone')" v-model="phone"/>
                </label>
                <label>
                  <div class="desc">*{{ $t('Email') }}</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email"/>
                </label>
                <label>
                  <div class="desc">*{{ $t('Password') }}</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass"/>
                </label>
                <label>
                  <div class="desc">*{{ $t('Confirm password') }}</div>
                  <input type="password" :placeholder="$t('Confirm password')" v-model="passConfirm"/>
                </label>
                <div class="captcha-wrapper">
                  <Spinner v-if="captchaIsLoading"></Spinner>
                  <img v-else :src="captchaSrc" @click="reloadCaptcha">
                </div>
                <label>
                  <input type="text" :placeholder="$t('Enter Captcha')" v-model="captcha"/>
                </label>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms"/>
                        <div class="checkbox"></div>
                        <span class="title">{{ $t('I agree with') }} </span>
                        <a @click="$parent.goToPage('privacy')" class="title">{{ $t('privacy policy') }}</a>
                        <span class="title">{{ $t('and') }}</span>
                        <a @click="$parent.goToPage('terms')" class="title">{{ $t('terms and conditions') }}</a>
                      </div>
                    </label>
                  </div>
                  <button :class="['button blue', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                    <span>{{ $t('Sign Up') }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{ $parent.error }}</div>
                </transition>
                <div class="modal-bottom">
                  <div class="desc">{{ $t('Already have an account?') }}</div>
                  <div class="link switch-register" @click="$emit('openSignInModal')">{{ $t('Log in with existing account') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Spinner from './Spinner.vue';

export default {
  name: 'SignUpModal',
  props: [],
  components: {
    Spinner
  },
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false,
      captchaSrc: '',
      captchaIsLoading: true,
      captcha: '',
      captchaKey: '',
    }
  },
  created() {
      this.reloadCaptcha();
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    reloadCaptcha() {
        this.captchaIsLoading = true;
        this.$http.get(process.env.VUE_APP_API + 'captcha').then(response => {
            this.captchaSrc = response.data.img;
            this.captchaKey = response.data.key;
            this.captchaIsLoading = false;
        });
    },
    closeSignUpModal() {
      this.$emit('closeSignInModal')
    },
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm,
        "captcha": this.captcha,
        "captchaKey": this.captchaKey
      }
        this.$emit('registration', regData)
    }
  }
}
</script>

<style scoped>
.captcha-wrapper{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & img{
    width: 250px;
  }
}
</style>